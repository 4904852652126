import axios from "axios";
import querystring from 'querystring'
import { apiUrl, getToken } from './utils'

const baseURL = apiUrl()
const token = getToken()

/**
 * Auth
 */
// Without header call
const instance = axios.create({
  baseURL: baseURL,
});

// With header call
const authInstance = axios.create({
  baseURL: baseURL,
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

// Updating authtoken in authInstance after login/register
const updateToken = (authToken) => {
  authInstance.defaults.headers["Authorization"] = `Bearer ${authToken}`;
};

const login = (values) => {
  // Call The login function
  return instance.post("/auth/local", {
    identifier: values.email,
    password: values.password,
  });
};

const register = (values) => {
  // Call The login function
  return instance.post("/auth/local/register", {...values, username:values.email});
};

const providerLogin = (values) => {
  // Call The login function
  return instance.get(`/stb-users/auth/${values.provider}/callback${values.params}`);
};

const forgotPassword = async (values) => {
  // Call The login function
  return await instance.post("/auth/forgot-password", values);
};

const emailConfirmation = async (params) => {
  // Call The login function
  // return await instance.get(`/auth/email-confirmation?${querystring.stringify(params)}`); // core
  return await instance.get(`/auth/confirm-email?${querystring.stringify(params)}`); // custom
};

const resetPassword = async (values) => {
  // Call The login function
  return await instance.post("/auth/reset-password", values);
};

const updateProfile = (params) => {
  return authInstance.put(`/users/${params.id}`, params);
};

const getUserProfile = (params) => {
  return authInstance.get("/users/me", params);
};

/**
 * User objects
 */

const getUserobjects = (params) => {
  return authInstance.get(`/stb-users/userobjects?${querystring.stringify(params)}`);
};

const createUserobject = (params) => {
  return authInstance.post("/stb-users/userobjects", params);
};

const updateUserobject = (params) => {
  return authInstance.put(`/stb-users/userobjects/${params.id}`, params);
};

const deleteUserobject = (params) => {
  return authInstance.delete(`/stb-users/userobjects/${params.id}`, params);
};

/**
 * Form
 */

const postForm = ( params ) => {
  return authInstance.post("/stb-forms/forms", params);
};


export {
  // auth
  updateToken,
  login,
  register,
  getUserobjects,
  providerLogin,
  forgotPassword,
  resetPassword,
  getUserProfile,
  updateProfile,
  createUserobject,
  updateUserobject,
  deleteUserobject,
  emailConfirmation,
  postForm,
};
