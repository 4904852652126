const Reducer = (state, action) => {
    switch (action.type) {
        case "DATA_LOADING":
            let params = {
                loading: true,
                error: false,
                success: false,
                message: "",
            }
            if (action?.payload) params = { ...params, ...action.payload }
            return {
                ...state,
                ...params,
            }
        case "GET_SAVED_PROPERTIES":
            let savedItems = {}
            if (action.payload) {
                savedItems = action.payload.data
            }
            return {
                ...state,
                loading: false,
                userObjects: { ...state.userObjects, ...savedItems },
            }

        case "GET_PROPERTIES":
            return {
                ...state,
                properties: action.payload,
            }

        case "SAVE_PROPERTY":
            // let saveItems = {}
            // if (action.payload && action.payload.type) {
            //     saveItems[action.payload.type] = action.payload.data
            // }
            return {
                ...state,
                userObjects: { ...state.userObjects, ...action.payload.data },
            }

        case "UPDATE_SAVED_PROPERTY":
            // let updateItems = {}
            // if (action.payload && action.payload.type) {
            //     updateItems[action.payload.type] = action.payload.data
            // }
            return {
                ...state,
                userObjects: { ...state.userObjects, ...action.payload.data },
            }

        case "DELETE_SAVED_PROPERTY":
            var userObjects = state.userObjects[action.payload.type]
            if (
                state.userObjects[action.payload.type] &&
                state.userObjects[action.payload.type].length
            ) {
                userObjects = {}
                userObjects[action.payload.type] = state.userObjects[
                    action.payload.type
                ].filter(x => x.id !== action.payload.id)
            }
            return {
                ...state,
                userObjects: { ...state.userObjects, ...userObjects },
            }
        case "UNSUBSCRIBE":
            return {
                ...state,
                loading: false,
                success: true,
                unsubscribe: action.payload,
            }
        case "ERROR":
            return {
              ...state,
              loading: false,
              error: true,
              success: false,
              message: action.payload.data.message,
              status: action.payload.status
            };
        case "SERVICE_ERROR":
            return {
              ...state,
              loading: false,
              error: true,
              success: false,
              message: action.payload.message,
              status: action.payload.status
            };

        default:
            return state
    }
}

export default Reducer