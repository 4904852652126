import {
    getUserobjects,
    getProperties,
    createUserobject,
    updateUserobject,
    deleteUserobject,
    unsubscribe,
} from "../api"
import md5 from "md5"
import _ from "lodash"

const services = (dispatch, actions) => ({
    getUserobject: payload => {
        dispatch(actions.savedActions("DATA_LOADING", { loading: true }))
        getUserobjects(payload).then(res => {
            dispatch(actions.savedActions("GET_SAVED_PROPERTIES", res.data))
        })
    },
    getProperties: payload => {
        getProperties(payload).then(res => {
            dispatch(actions.savedActions("GET_PROPERTIES", res.data))
        })
    },
    createUserobject: payload => {
        // const data = { ...payload, type_id: md5(payload.params) };
        payload.type_id = md5(payload.params)
        createUserobject(payload).then(res => {
            dispatch(actions.savedActions("SAVE_PROPERTY", res.data))
        })
    },
    updateUserobject: payload => {
        updateUserobject(payload).then(res => {
            dispatch(actions.savedActions("UPDATE_SAVED_PROPERTY", res.data))
        })
    },
    deleteUserobject: payload => {
        deleteUserobject(payload).then(res => {
            dispatch(actions.savedActions("DELETE_SAVED_PROPERTY", res.data))
        })
    },
    isSaved: (userObjects, params, type) => {
        if (_.isEmpty(userObjects)) return false

        if (_.isEmpty(userObjects[type])) return false

        return !!userObjects[type].filter(
            x => JSON.stringify(x.params).toLowerCase() === JSON.stringify(params).toLowerCase() && x.state
        ).length
    },
    unsubscribe: payload => {
        unsubscribe(payload)
            .then(res => {
                dispatch(actions.savedActions("UNSUBSCRIBE", res.data))
            })
            .catch(error => {
                if (error.response) {
                    dispatch(actions.savedActions("ERROR", error.response))
                } else {
                    dispatch(
                        actions.savedActions("SERVICE_ERROR", {
                            message: "Api service Down",
                            status: 500,
                        })
                    )
                }
            })
    },
})

export default services
