
import { navigate } from "@reach/router"
import CheckWebpFeature from "./src/components/common/ggfx-client/module/webp-checker"
CheckWebpFeature();

export { wrapRootElement } from "./src/apollo/wrap-root-element";

export const shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition
}) => {
  
    // Avoid to scroll to top when next page trigger click
    // But this feature should condiser with details page back scroll too
    if (location.pathname.match(/property\/for-sale/)) {
      return false;
    }
    if (location.pathname.match(/property\/to-rent/)) {
      return false;
    }

    if (location.pathname.match(/property\/commercial\/for-sale/)) {
      return false;
    }
    if (location.pathname.match(/property\/commercial\/to-rent/)) {
      return false;
    }

    if (location.pathname.match(/property\/new-homes\/for-sale/)) {
      return false;
    }
    if (location.pathname.match(/property\/new-homes\/to-rent/)) {
      return false;
    }
  
    return true;
};


const addScript = url => {
  const hash = typeof window !== 'undefined' ? window.location.hash : ''

  if(hash === "#book_a_valuation") {
    navigate(`/market-your-property/book-a-valuation/`)
  }

  if(url){
    const script = document.createElement("script")
    script.src = url
    script.async = true
    document.body.appendChild(script)
  }
}

const addwidgetScript = url => {
  const pageurl = typeof window !== 'undefined' ? window.location.href : ''
  const pathname = typeof window !== 'undefined' ? window.location.pathname : ''
  if(url){
  if (pageurl?.includes("/property-services/landlord") || pathname === "/") {
    const script = document.createElement("script")
    script.src = url
    document.body.appendChild(script)
    const scriptdiv = document.createElement("div")
    scriptdiv.id = "yuno-widget"
    scriptdiv.title = "Landlords"
    scriptdiv.text = "Avoid a £30K fine with our free compliance checker."
    scriptdiv.url = "https://martyngerrard.goyuno.com"
    document.body.appendChild(scriptdiv)
      }

      // else {
        // document.getElementById("yuno-widget").remove();
      // }
  
   
  }

}

export const onClientEntry = () => {
  window.onload = () => {
    addScript()
    // addwidgetScript("https://yuno-uat.s3.eu-west-2.amazonaws.com/yuno-widget-js/app.js")
    //addScript("https://clients.yomdel.com/tools/chat_script.js?url=" + document.location.href)
  }
}