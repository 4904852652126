import {
  login,
  register,
  updateToken,
  providerLogin,
  forgotPassword,
  resetPassword,
  emailConfirmation,
  getUserProfile,
  getPendingActionCount,
  createUserobject
} from '../api';
import {
  isBrowser,
  apiUrl,
  storeData,
  getData,
  removeData,
  storeUserData
} from '../utils';
// import { MYACCOUNT_DASHBOARD } from "@myaccountUrls";
// import { doRedirect } from "@myaccount/utils";

import md5 from 'md5';

const baseURL = apiUrl();

const storeToken = (data) => {
  // Store token
  if (isBrowser()) {
    storeData('sjwt', data.jwt);
    storeUserData(data.user);
  }

  updateToken(data.jwt);
};

const removeToken = (data) => {
  // Store token
  removeData('sjwt');
  removeData('suser');
};

const services = (dispatch, actions) => ({
  storeData: (key, value) => {
    storeData(key, value);
  },
  getData: (key) => {
    return getData(key);
  },
  removeData: (key) => {
    removeData(key);
  },
  registerWithEmail: (payload) => {
    dispatch(actions.authLoading());
    register(payload)
      .then((res) => {
        // Auto confirm user
        if (res.data.user.confirmed) {
          // Login success
          storeToken(res.data);
          dispatch(actions.authProcess(res));
        } else {
          // Email verification required
          dispatch(actions.verifyProcess(res));
        }
      })
      .catch((error) => {
        // Login failed
        if (error.response) {
          // Login error
          dispatch(
            actions.authError({
              message: error.response.data.message,
              status: error.response.status
            })
          );
        } else {
          // Service error
          dispatch(
            actions.serviceError({
              message: 'Api service Down',
              status: 500
            })
          );
        }
      });
  },

  loginWithEmail: (payload) => {
    dispatch(actions.authLoading());
    login(payload)
      .then((res) => {
        // Login success
        storeToken(res.data);
        dispatch(actions.authProcess(res));
      })
      .catch((error) => {
        // Login failed
        if (error.response) {
          // Login error
          dispatch(
            actions.authError({
              message: error.response.data.message,
              status: error.response.status
            })
          );
        } else {
          // Service error
          dispatch(
            actions.serviceError({
              message: 'Api service Down',
              status: 500
            })
          );
        }
      });
  },

  isAuthenticated: () => {
    if (isBrowser()) {
      return !!getData('sjwt');
    } else {
      return false;
    }
  },

  logout: () => {
    if (isBrowser()) {
      removeToken();
    }
    // do redirect to default page
    // doRedirect(MYACCOUNT_DASHBOARD)
    // dispatch(actions.authLogout());
  },

  // Successfully logged with the provider
  // Now logging with strapi by using the access_token (given by the provider) in props.location.search
  providerAuthLogin: (provider, params) => {
    providerLogin({ provider: provider, params: params })
      .then((res) => {
        // Login success
        storeToken(res.data);
        dispatch(actions.authProcess(res));
      })
      .catch((error) => {
        // Login failed
        if (error.response) {
          // Login error
          dispatch(
            actions.providerLoginError({
              message: error.response.data,
              status: error.response.status
            })
          );
        } else {
          // Service error
          dispatch(
            actions.serviceError({
              message: 'Api service Down',
              status: 500
            })
          );
        }
      });
  },

  forgottenPassword: async (payload) => {
    await forgotPassword(payload)
      .then((res) => {
        // Login success
        dispatch(actions.forgotPassword(res));
      })
      .catch((error) => {
        // Failed
        if (error.response) {
          dispatch(
            actions.authError({
              message: error.response.data.message,
              status: error.response.status
            })
          );
        } else {
          // Service error
          dispatch(
            actions.serviceError({
              message: 'Api service Down',
              status: 500
            })
          );
        }
      });
  },

  resetPassword: async (payload) => {
    await resetPassword(payload)
      .then((res) => {
        // Login success
        storeToken(res.data);
        dispatch(actions.authProcess(res));
      })
      .catch((error) => {
        // failed
        if (error.response) {
          dispatch(
            actions.authError({
              message: error.response.data.message,
              status: error.response.status
            })
          );
        } else {
          // Service error
          dispatch(
            actions.serviceError({
              message: 'Api service Down',
              status: 500
            })
          );
        }
      });
  },

  emailConfirmation: async (payload) => {
    await emailConfirmation(payload)
      .then((res) => {
        // Login success
        storeToken(res.data);
        dispatch(actions.authProcess(res));
      })
      .catch((error) => {
        // Login failed
        if (error.response) {
          // Login error
          dispatch(
            actions.providerLoginError({
              message: error.response.data.message,
              status: error.response.status
            })
          );
        } else {
          // Service error
          dispatch(
            actions.serviceError({
              message: 'Api service Down',
              status: 500
            })
          );
        }
      });
  },

  getUserProfile: async (payload) => {
    await getUserProfile(payload)
      .then((res) => {
        storeUserData(res.data);

        dispatch(actions.getUserData(res));
      })
      .catch((error) => {
        // failed
        if (error.response) {
          if (error.response?.data?.statusCode === 401) removeToken();
          dispatch(
            actions.tokenError({
              message: error.response.data.message,
              status: error.response.data.statusCode
            })
          );
        } else {
          // Service error
          dispatch(
            actions.serviceError({
              message: 'Api service Down',
              status: 500
            })
          );
        }
      });
  },

  resetMessage: async (payload) => {
    dispatch(actions.resetMessage(payload));
  },

  updateMessage: async (payload) => {
    dispatch(actions.updateMessage(payload));
  },
  getPendingActionsCount: async (payload) => {
    getPendingActionCount(payload).then((res) => {
      dispatch(actions.getPendingActionCount(res.data));
    });
  },
  createUserobject: (payload) => {
    // const data = { ...payload, type_id: md5(payload.params) };
    payload.type_id = md5(payload.params);
    createUserobject(payload).then((res) => {
      // post message here
    });
  },
  providerLogin: (provider) => {
    if (isBrowser()) {
      window.location.href = `${baseURL}/connect/${provider}`;
    }
  }
});

export default services;
